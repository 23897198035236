import "./circular-loader.scss";

export default function CircularLoader({
    color,
    size = "small",
}: {
    color?: "black" | "white";
    size?: "small" | "large";
}) {
    return (
        <div
            className={`circular-loader ${color === "black" ? "circular-loader-black" : ""} ${size === "small" ? "" : "circular-loader-large"}`}
        ></div>
    );
}
